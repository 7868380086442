<script setup>
import { ChevronDoubleRightIcon } from '@heroicons/vue/24/outline'
const props = defineProps({
    title: String,
    linkTo: String,
    query: Object
})
</script>
<template>
    <div class="space-y-4 group text-center cursor-default">
        <div class="text-red-primary w-28 h-28 mx-auto group-hover:scale-125 group-hover:-translate-y-4 duration-300">
            <slot />
        </div>
        <h3 class="font-bold uppercase">{{ props.title }}</h3>
        <div class="text-sm lg:text-base">
            <slot name="heading"></slot>
        </div>
        <Link
            v-if="props.linkTo && !props.query"
            class="block"
            :href="route(props.linkTo)"
        >
            <div class="text-red-primary flex gap-2 font-bold justify-center">
                <p>LEARN MORE</p>
                <ChevronDoubleRightIcon class="w-4" />
            </div>
        </Link>
        <Link
            v-else-if="props.query && props.linkTo"
            class="block"
            :href="route(props.linkTo, query)"
        >
            <div class="text-red-primary flex gap-2 font-bold justify-center">
                <p>LEARN MORE</p>
                <ChevronDoubleRightIcon class="w-4 group-hover:translate-x-2 duration-500" />
            </div>
        </Link>
    </div>
</template>
